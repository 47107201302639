<div class="wuc-modal">
  <div class="wuc-modal__container" id="wuc-modal-container" #modal role="dialog" aria-labelledby="dialog-title">
    <div class="wuc-modal__backdrop"></div>
    <div
      class="wuc-modal__overlay-container"
      #overlay
      (click)="onOverlayClick($event, overlay)"
      [class.wuc-modal__overlay-container--fullscreen]="isFullScreen"
    >
      <div class="wuc-modal__overlay" [class.wuc-modal__overlay--fullscreen]="isFullScreen">
        <div class="wuc-modal__header">
          <div class="wuc-modal__header-titles" *ngIf="modalTitle || modalSubTitle">
            <h2 class="wuc-modal__title" [title]="modalTitle">
              {{ modalTitle }}
            </h2>
            <p class="wuc-modal__sub-title" [title]="modalSubTitle">
              {{ modalSubTitle }}
            </p>
          </div>
          <div class="wuc-modal__close" *ngIf="showClose">
            <wuc-link type="secondary" iconRight="cross-closed" iconSize="xs" (clicked)="onCloseClick()">
              <span>{{ closeButtonText }}</span>
            </wuc-link>
          </div>
        </div>
        <div class="wuc-modal__content">
          <ng-content></ng-content>
        </div>
        <div class="wuc-modal__footer">
          <ng-content select="[slot=footer]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
