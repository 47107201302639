import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Optional,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import Pikaday from 'pikaday';
import { WucInputBaseComponent } from '../input-base.component';
import { InputInterface } from '../input.interface';
import { PikadayService } from './pikaday.service';
import { TemporaryDateService } from './temporary-date.service';

@Component({
    selector: 'wuc-input-date-picker',
    templateUrl: 'input-date-picker.component.html',
    styleUrls: ['input-date-picker.component.scss', '../input-date-picker/pikaday.scss'],
    // eslint-disable-next-line
    inputs: WucInputBaseComponent.inputs,
    providers: [{ provide: WucInputBaseComponent, useExisting: WucInputDatePickerComponent }],
})
export class WucInputDatePickerComponent
    extends WucInputBaseComponent
    implements ControlValueAccessor, InputInterface, AfterViewInit, OnChanges, OnDestroy
{
    @Input() public selectPastDates: boolean = true;
    @Input() public selectFutureDates: boolean = true;
    @Input() public selectToday: boolean = true;
    @Input() public minDate!: string;
    @Input() public maxDate!: string;
    @Input() public allowTextInput: boolean = false;
    @Input() public initializeWithDate: boolean = false;

    @ViewChild('input') public inputElement!: ElementRef;
    @ViewChild('icon') public iconElement!: ElementRef;
    @ViewChild('pikadayContainer') public pikadayContainerElement!: ElementRef;

    public isDisabled: boolean = false;
    public value?: string;
    public onChange!: (value: string) => void;
    public onTouched!: () => void;

    private pikaday!: Pikaday;
    private readonly seperator: string = TemporaryDateService.getSeperator(this.pikadayService.placeholder);

    constructor(
        public pikadayService: PikadayService,
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() public override ngControl: NgControl
    ) {
        super(ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngAfterViewInit(): void {
        this.getPikadayInstance().then((pikaday: any) => {
            this.pikaday = pikaday;
            if (this.initializeWithDate) this.onChange(TemporaryDateService.dateToString(pikaday.getDate()));
        });
    }

    public ngOnChanges(): void {
        if (this.pikaday) {
            this.updatePikadayInstance();
        }
    }

    public ngOnDestroy(): void {
        if (this.pikaday) {
            this.pikaday.destroy();
        }
    }

    public onInputBlur(): void {
        if (!this.allowTextInput) {
            return;
        }

        if (!this.inputElement.nativeElement.value) {
            this.pikaday.setDate(null);
            this.onChange('');
        } else {
            this.inputElement.nativeElement.value = TemporaryDateService.dateStringToDayFirst(
                TemporaryDateService.dateToString(this.pikaday.getDate(), this.seperator)
            );
        }
        this.onTouched();
        this.changeDetectorRef.markForCheck();
        this.pikaday.hide();
    }

    // Update value from API; API => view
    public writeValue(value: string): void {
        this.value = TemporaryDateService.replaceSeperator(
            TemporaryDateService.dateStringToDayFirst(value),
            this.seperator
        );
    }

    // Update value from view "on change"; View => API
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Update value from view "on blur"; View => API
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Set disabled state from API
    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    private getPikadayInstance(): Promise<Pikaday> {
        const minDate = this.getMinDate(this.minDate);
        const maxDate = this.getMaxDate(this.maxDate);

        /* istanbul ignore next */
        return this.pikadayService.getInstance({
            field: this.inputElement.nativeElement,
            minDate: minDate ? new Date(minDate) : undefined,
            maxDate: maxDate ? new Date(maxDate) : undefined,
            onSelect: (date: Date) => this.onChange(TemporaryDateService.dateToString(date)),
            onClose: () => {
                this.onTouched();
                this.changeDetectorRef.markForCheck();
            },
            defaultDate: minDate ? new Date(minDate) : new Date(),
            setDefaultDate: true,
            toString: (date: Date) =>
                TemporaryDateService.dateStringToDayFirst(TemporaryDateService.dateToString(date, this.seperator)),
            disableDayFn: (date: Date) => !this.selectToday && TemporaryDateService.isDateToday(date),
            container: this.pikadayContainerElement.nativeElement,
            trigger: this.allowTextInput ? this.iconElement.nativeElement : this.inputElement.nativeElement,
        });
    }

    private updatePikadayInstance(): void {
        const minDate = this.getMinDate(this.minDate);
        const maxDate = this.getMaxDate(this.maxDate);

        this.pikaday.setMinDate(minDate ? new Date(minDate) : null);
        this.pikaday.setMaxDate(maxDate ? new Date(maxDate) : null);
    }

    private getMaxDate(maxDate: string): string {
        if (!maxDate && !this.selectFutureDates) {
            return this.selectToday ? TemporaryDateService.today : TemporaryDateService.yesterday;
        }
        return maxDate;
    }

    private getMinDate(minDate: string): string {
        if (!minDate && !this.selectPastDates) {
            return this.selectToday ? TemporaryDateService.today : TemporaryDateService.tomorrow;
        }
        return minDate;
    }

    @HostListener('document:keydown', ['$event'])
    public handleEmptyDateEvent(event: KeyboardEvent): boolean {
        const key = event.keyCode;
        if ((key === 8 || key === 46) && this.pikaday.isVisible()) {
            // Backspace or delete is pressed
            this.onChange('');
        }
        return true;
    }
}
