/* eslint-disable max-len */
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PrettyLicensePlateService {
    private licensePartsRegex: RegExp = /([\d]{1,5}|[A-Z]{1,5})/gi;
    private hasThreePartRegex: RegExp = /([\d]{3}|[A-Z]{3})/gi;
    private diplomatLicensePlateRegex: RegExp = /^CD[ABFJNST]\d{1,3}$/; // for example: CDB1 of CDJ45

    /**
     * Formate license plate for a given locale id
     * @param licensePlate string
     * @param locale 'NL' | 'DE' | 'ES'
     */
    public format(licensePlate: string, locale: 'NL' | 'DE' | 'ES'): string {
        switch (locale) {
            case 'NL':
                return this.formatNL(licensePlate);
            case 'ES':
                return this.formatES(licensePlate);
        }

        return licensePlate;
    }

    /**
     * Remove visual separators
     * @param licensePlate string
     */
    public trim(licensePlate: string): string {
        return licensePlate.toLowerCase().replace(/[-+]/g, '');
    }

    /**
     * Format any possible (dutch) license plate
     * @param licensePlate string
     */
    private formatNL(licensePlate: string): string {
        // license plates for diplomats should not be formatted
        if (licensePlate.match(this.diplomatLicensePlateRegex)) {
            return licensePlate;
        }

        const parts = licensePlate.toUpperCase().match(this.licensePartsRegex);
        if (!parts) {
            return '';
        }

        return parts.reduce(
            (previousValue: string, currentValue: string) => this.licensePlateReducer(previousValue, currentValue),
            ''
        );
    }

    private formatES(licensePlate: string): string {
        const formattedLicensePlate = this.trim(licensePlate).toUpperCase();

        if (formattedLicensePlate.length < 5) {
            return formattedLicensePlate;
        }

        const numbers = formattedLicensePlate.slice(0, 4);
        const letters = formattedLicensePlate.slice(4, 7);

        return `${numbers}-${letters}`;
    }

    /**
     * We need to format the license plate differently for specific scenarios, but they all need to be seperated by dashes in the end
     * @param previousValue string
     * @param currentValue string
     */
    private licensePlateReducer(previousValue: string, currentValue: string): string {
        const output: string[] = [];

        if (currentValue.length === 4 || (currentValue.length === 3 && previousValue.match(this.hasThreePartRegex))) {
            output.push(currentValue.substr(0, 2));
            output.push(currentValue.substr(2));
        } else if (currentValue.length === 5) {
            output.push(currentValue.substr(0, 3));
            output.push(currentValue.substr(3));
        } else {
            output.push(currentValue);
        }

        if (previousValue.length > 0) {
            output.unshift(previousValue);
        }

        return output.join('-');
    }
}
